import React from 'react'
import firebase from "gatsby-plugin-firebase"

export const AuthContext = React.createContext()

export const useAuth = () => (
	React.useContext(AuthContext)
)

const AuthProvider = ({children}) => {
	const [currentUser, setCurrentUser] = React.useState(null)
	const [authLoading, setAuthLoading] = React.useState(true)

	const login = (email, password) => (firebase.auth().signInWithEmailAndPassword(email, password))
	const reset = (email) => (firebase.auth().sendPasswordResetEmail(email))
	const logout = () => {
		firebase.auth().signOut()
		setCurrentUser("");
	}

	React.useEffect(() => {
		/** check if the user exist  */
		const unSubscribe = firebase.auth().onAuthStateChanged(user => {
			setCurrentUser(user)
			setAuthLoading(false)
		})
		
		/** clean up === unsub from firebase listinner */
		return unSubscribe
	}, [])

	return (
		<AuthContext.Provider value={{currentUser, setCurrentUser, login, reset, logout}}>
			{!authLoading && children}
		</AuthContext.Provider>
	)
}

export default AuthProvider


