/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */



import "tailwindcss/dist/base.min.css"
/**
 * dont forget to purge the app
 * https://dev.to/ctrleffive/how-i-purged-95-of-unused-bootstrap-css-from-my-gatsby-website-3khi
 * 
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import "firebase/auth"
import React from "react"
import AuthProvider from "./src/context/auth"


export const wrapRootElement = ({element}) => (
	<AuthProvider>
		{element}
	</AuthProvider>
)